import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { ViewState } from 'src/app/models/app';
import { DefinitionDto } from 'src/app/models/dtos';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  // styleUrls: ['./definition.component.css']
})
export class DefinitionComponent implements OnInit {
  constructor(private http: HttpClient) { }

  //inputs
  @Input() definition!: DefinitionDto;

  //outputs
  @Output() didDeleteDefinition: EventEmitter<DefinitionDto> = new EventEmitter();

  //vars  
  moment: any = moment;
  expanded: boolean = false;

  //view states
  viewStates = ViewState;
  viewState = ViewState.content;

  //editors
  public ContentEditor = DocumentEditor;

  ngOnInit() {
  }

  //api
  editDefinition() {
    this.viewState = ViewState.loading;

    this.http
      .put<DefinitionDto>(`${environment.services_legacy_management}/v1/definitions/${this.definition.id}`, this.definition)
      .subscribe(definition => {
        this.viewState = ViewState.content;
        this.expanded = false;
      }, (error) => {

      });
  }

  deleteDefinition() {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_legacy_management}/v1/definitions/${this.definition.id}`)
      .subscribe(() => {
        this.didDeleteDefinition.emit(this.definition);
      }, (error) => {

      });
  }

  //alerts
  confirmDeleteDefinition() {
    Swal.fire({
      title: "Confirm Delete Definition",
      text: "Are you sure you want to delete this definition?",
      icon: 'warning',
      confirmButtonText: 'Delete',
      confirmButtonColor: "#ff5370",
      showCancelButton: true
    }).then((state) => {
      /* Read more about isConfirmed, isDenied below */
      if (state.value) {
        this.deleteDefinition();
      }
    });
  }

  //editors
  public onReady(editor: any) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.ui.getEditableElement().style.height = '400px';
    editor.ui.getEditableElement().style.maxHeight = '400px';

    // editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    //   return new MyUploadAdapter(loader, this.http);
    // };
  }
}
