<div class="animated fadeIn">
  <!--loading-->
  <div *ngIf="viewState == viewStates.loading" class="text-center">
    <div class="spinner-border text-ts" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <c-card *ngIf="viewState == viewStates.content" class="mb-4 bg-transparent border-0">
    <c-card-body>
      <c-row>
        <c-col sm="7">
          <h3 class="card-title mb-0" id="traffic">
            <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded me-2"
              style="object-fit: contain; width: 30px; height: 30px;">
            <b>All Definitions</b>
          </h3>
        </c-col>
        <c-col sm="5">
          <div style="text-align: right;">
            <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddDefinition()">
              <i class="fa fa-plus mr-2"></i> Add Definition
            </button>
          </div>
        </c-col>
      </c-row>

      <c-row>
        <c-col [xs]="12">
          <div class="flex items-center mt-4 sm:mt-0 md:mt-4">
            <!-- Search -->
            <div class="flex-auto">
              <div class="form-group">
                <input type="text" id="search-definitions" placeholder="Search definitions..." class="form-control" [(ngModel)]="searchText">
              </div>
            </div>
          </div>
        </c-col>
      </c-row>

      <c-row class="mt-4">
        <c-col xs="12" *ngFor="let definition of filteredDefinitions(); let i = index">
          <div class="mb-2">
            <app-definition [definition]='definition' (didDeleteDefinition)="didDeleteDefinition($event)"></app-definition>
          </div>
        </c-col>
      </c-row>
    </c-card-body>
  </c-card>
  <!-- 
  <div *ngIf="viewState == viewStates.content" class="card border-0 bg-transparent">
    <div class="card-header bg-transparent">
      <span class="h3">
        <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded mr-2"
          style="object-fit: contain; width: 30px; height: 30px;">
        <b class="align-middle">All Definitions</b>
      </span>
      <div class="card-header-actions">
        <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddDefinition()">
          <i class="fa fa-plus mr-2"></i> Add Definition
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row text-center">
        <div class="col-sm-3" *ngFor="let definition of definitions; let i = index">
          <app-definition-card [definition]='definition'></app-definition-card>
        </div>
      </div>
    </div>
  </div>
</div> -->

  <!--add definition modal-->
  <div bsModal #addDefinitionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="addDefinitionModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Definition</h4>
          <button type="button" class="close" (click)="addDefinitionModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!--loading-->
          <div *ngIf="addDefinitionViewState == viewStates.loading" class="text-center">
            <div class="spinner-border text-ts" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <!--info-->
          <div *ngIf="addDefinitionViewState == viewStates.content">
            <!--info-->
            <div class="form-group">
              <label for="name">Title</label>
              <input type="text" id="definition-title" placeholder="Definition name" class="form-control"
                value={{newDefinition.name}} [(ngModel)]="newDefinition.name">
            </div>
            <div class="form-group">
              <label for="abbreviation">Abbreviation</label>
              <input type="text" id="definition-abbreviation" placeholder="Definition abbreviation" class="form-control"
                value={{newDefinition.abbreviation}} [(ngModel)]="newDefinition.abbreviation">
            </div>
            <div class="form-group">
              <label for="name">Description</label>
              <textarea type="text" id="definition-desc" placeholder="Definition description" class="form-control"
                value={{newDefinition.description}} [(ngModel)]="newDefinition.description"></textarea>
            </div>

            <!--checkboxes-->
            <div class="row text-center">
              <div class="col-md-12">
                <label class="switch switch-label switch-ts">
                  <input type="checkbox" class="switch-input" [checked]="newDefinition.free"
                    (change)="newDefinition.free = !newDefinition.free">
                  <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                </label>
                <div>Free Preview</div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="addDefinitionModal.hide()">Close</button>
          <button type="button" class="btn btn-dark btn-ts" (click)="addDefinition(newDefinition)">Save changes</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->