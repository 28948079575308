import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ViewState } from 'src/app/models/app';
import { DefinitionDto } from 'src/app/models/dtos';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-definitions',
  templateUrl: './definitions.component.html',
  // styleUrls: ['./definitions.component.css']
})
export class DefinitionsComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title) { }

  //vars
  public definitions: DefinitionDto[] = [];
  newDefinition: DefinitionDto = new DefinitionDto();
  searchText: string = '';

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addDefinitionViewState = ViewState.content;

  //modals
  @ViewChild('addDefinitionModal') public addDefinitionModal?: ModalDirective;

  ngOnInit() {
    this.titleService.setTitle('All Definitions | Trucking Standards Admin');
    this.getDefinitions();
  }

  //api
  getDefinitions(): void {
    this.viewState = ViewState.loading;

    this.http
      .get<DefinitionDto[]>(`${environment.services_legacy_management}/v1/definitions`)
      .subscribe(result => {
        this.definitions = result;
        this.viewState = ViewState.content;
      });
  }

  addDefinition(newDefinition: DefinitionDto) {
    this.addDefinitionViewState = ViewState.loading;

    this.http
      .post<DefinitionDto>(`${environment.services_legacy_management}/v1/definitions`, newDefinition)
      .subscribe(definition => {
        this.definitions.push(definition);
        this.addDefinitionModal?.hide();
        this.addDefinitionViewState = ViewState.content;
      }, (error) => {

      });
  }

  //modal
  presentAddDefinition() {
    this.newDefinition = new DefinitionDto();
    this.addDefinitionModal?.show();
  }

  filteredDefinitions(): DefinitionDto[] {
    const q = this.searchText.toLowerCase();

    return this.definitions
      .filter(d => (d.name ?? '').toLowerCase().includes(q)
        || (d.abbreviation ?? '').toLowerCase().includes(q)
        || (d.description ?? '').toLowerCase().includes(q));
  }

  didDeleteDefinition(definition: DefinitionDto) {
    this.definitions = this.definitions.filter(d => d.id != definition.id);
  }
}
