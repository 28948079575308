<c-card>
  <c-card-header *ngIf="viewState == viewStates.loading">
    <!--loading-->
    <div class="text-center">
      <div class="spinner-border text-ts" role="status">
        <span class="sr-only">Saving...</span>
      </div>
    </div>
  </c-card-header>
  <c-card-header *ngIf="viewState == viewStates.content">
    <c-row>
      <c-col [sm]="12" [md]="7">
        <div class="form-group">
          <label for="name"><small>Name</small></label>
          <input type="text" id="definition-name" placeholder="Definition name" class="form-control"
            value={{definition.name}} [(ngModel)]="definition.name">
        </div>
      </c-col>
      <c-col [sm]="12" [md]="3">
        <div class="form-group">
          <label for="name"><small>Abbreviation</small></label>
          <input type="text" id="definition-abbreviation" placeholder="Definition abbreviation" class="form-control"
            value={{definition.abbreviation}} [(ngModel)]="definition.abbreviation">
        </div>
      </c-col>
      <c-col [sm]="12" [md]="1">
        <button class="btn btn-dark btn-ts w-100 mt-4" (click)="editDefinition()">
          <i class="fa fa-save"></i>
        </button>
      </c-col>
      <c-col [sm]="12" [md]="1">
        <button class="btn btn-danger w-100 mt-4" (click)="confirmDeleteDefinition()">
          <i class="fa fa-trash"></i>
        </button>
      </c-col>
      <c-col [sm]="12" *ngIf="!expanded">
        <button class="btn btn-secondary w-100 mt-2" (click)="expanded = true">Expand Details</button>
      </c-col>
      <c-col [sm]="12" *ngIf="expanded">
        <button class="btn btn-secondary w-100 mt-2" (click)="expanded = false">Hide Details</button>
      </c-col>
    </c-row>
  </c-card-header>
  <c-card-body *ngIf="expanded && viewState == viewStates.content">
    <c-row>
      <c-col [sm]="12" [md]="8">
        <div class="form-group">
          <label for="name"><small>Website</small></label>
          <input type="text" id="definition-website" placeholder="Definition website" class="form-control"
            value={{definition.website}} [(ngModel)]="definition.website">
        </div>
      </c-col>
      <c-col [sm]="12" [md]="4" class="pt-4">
        <label class="switch switch-label switch-ts">
          <input type="checkbox" class="switch-input" [checked]="definition.free"
            (change)="definition.free = !definition.free">
          <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
        </label>
        <span class="ps-2">Free Preview</span>
      </c-col>
    </c-row>
    <c-row [xs]="12" class="mt-2">
      <label for="name"><small>Description</small></label>
      <ckeditor [editor]="ContentEditor" (ready)="onReady($event)" [(ngModel)]="definition.description"></ckeditor>
    </c-row>
  </c-card-body>
</c-card>